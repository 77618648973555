import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/text-area/TextArea.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const InternalContent = makeShortcode("InternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/text-area" storybook="https://workday.github.io/canvas-kit/?path=/story/components-inputs-textarea--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=3984-274&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/567e9c645fe813d94dd1f3491954da5b/536c7/component-anatomy-text-area.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "24.864864864864867%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Text Area in its default state with top label.",
              "title": "Image of a Text Area in its default state with top label.",
              "src": "/static/567e9c645fe813d94dd1f3491954da5b/50383/component-anatomy-text-area.png",
              "srcSet": ["/static/567e9c645fe813d94dd1f3491954da5b/1efb2/component-anatomy-text-area.png 370w", "/static/567e9c645fe813d94dd1f3491954da5b/50383/component-anatomy-text-area.png 740w", "/static/567e9c645fe813d94dd1f3491954da5b/536c7/component-anatomy-text-area.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Label:`}</strong>{` Title of the Text Area.`}</li>
        <li parentName="ol"><strong parentName="li">{`Input Container:`}</strong>{` Rectangular container that houses the placeholder and body text.`}</li>
        <li parentName="ol"><strong parentName="li">{`Placeholder/Body Text:`}</strong>{` Placeholder text is optional and shows an example of how the text is
used.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`Use the Text Area component when you need to let users enter an amount of text that’s longer than
a single line.`}</li>
        <li parentName="ul">{`To ensure we don’t overwhelm users, there shouldn’t be more than two Wide Text Areas on a page.`}</li>
        <li parentName="ul">{`For all Text Areas on Web, a user clicking into a field or label that's not disabled will trigger
the text cursor to appear, allowing users the ability to type. As the user types in the Text Area,
the placeholder text is replaced with the user’s input.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`Use the Text Area to fit longer text descriptions, usually around one paragraph.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`Use a Rich Text Editor to give users the ability to format text.`}</li>
        <li parentName="ul">{`Use a `}<a parentName="li" {...{
            "href": "/components/inputs/text-input"
          }}>{`Text Input`}</a>{` for single line of text.`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "how-text-areas-impact-the-accessible-experience"
      }}>{`How Text Areas Impact the Accessible Experience`}</h3>
      <p>{`Programmatically associating labels and instructions to a form input is one of the most important
foundational practices for building accessible forms.`}</p>
      <p>{`For Text Area, placeholder values are best used for hints or examples of valid input. Designers
should avoid relying on placeholder text for explaining the purpose of the input, as placeholders
often don’t provide enough contrast against the text input background. In addition to lower
contrast, placeholders disappear once users enter information, impacting users with memory loss,
cognitive disabilities, or who are simply distracted.`}</p>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Each Text Area must have a focus indicator that is highly visible against the background and against
the non-focused state. Refer to `}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for more
information.`}</p>
      <p>{`Text Area must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: focus into and out of each text area`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Text Area must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`This component is a multi-line text field`}</li>
        <li parentName="ul">{`The accessible name (label) describing the purpose of the field`}</li>
        <li parentName="ul">{`Text or placeholder values inside the field`}</li>
        <li parentName="ul">{`If applicable, whether the field is required, disabled, or invalid`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <ul>
        <li parentName="ul">{`No design annotations needed`}</li>
      </ul>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`Text Area must have an `}<inlineCode parentName="li">{`aria-describedby`}</inlineCode>{` attribute referencing the unique `}<inlineCode parentName="li">{`id`}</inlineCode>{` value of the error
text when an inline alert or error is shown.`}</li>
        <li parentName="ul">{`Text Area must have a `}<inlineCode parentName="li">{`required`}</inlineCode>{` attribute when the field is required for form submission.`}</li>
        <li parentName="ul">{`[Included in component]`}{` A `}<inlineCode parentName="li">{`<label>`}</inlineCode>{` element must be added with a `}<inlineCode parentName="li">{`for`}</inlineCode>{` attribute referencing the
unique `}<inlineCode parentName="li">{`id`}</inlineCode>{` value of the associated text area.`}</li>
        <li parentName="ul">{`[Included in component]`}{` An `}<inlineCode parentName="li">{`aria-invalid=”true”`}</inlineCode>{` attribute must be set on the Text Area when the
field has an error condition.`}</li>
        <li parentName="ul">{`[Included in component]`}{` A `}<inlineCode parentName="li">{`disabled`}</inlineCode>{` attribute must be set on the Text Area when the field is
disabled.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`Refer to the guidelines on `}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/placeholder-text"
          }}>{`Placeholder Text`}</a>{` in the Content
Style Guide for more tips on how to write placeholder text.`}</li>
      </ul>
    </TabPanel>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="iOS" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?node-id=20%3A4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-1"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/e0665ed7bc2fbeac6ae25d9cb3693521/536c7/component-anatomy-textarea-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "29.72972972972973%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image detailing Text Area anatomy",
                "title": "Image detailing Text Area anatomy",
                "src": "/static/e0665ed7bc2fbeac6ae25d9cb3693521/50383/component-anatomy-textarea-mobile.png",
                "srcSet": ["/static/e0665ed7bc2fbeac6ae25d9cb3693521/1efb2/component-anatomy-textarea-mobile.png 370w", "/static/e0665ed7bc2fbeac6ae25d9cb3693521/50383/component-anatomy-textarea-mobile.png 740w", "/static/e0665ed7bc2fbeac6ae25d9cb3693521/536c7/component-anatomy-textarea-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Label`}</strong>{`: Title of the Text Area.`}</li>
          <li parentName="ol"><strong parentName="li">{`Input Container`}</strong>{`: Rectangular container that houses the placeholder and body text.`}</li>
          <li parentName="ol"><strong parentName="li">{`Placeholder/Body Text`}</strong>{`: Placeholder text is optional and shows an example text entry or
instructional text.`}</li>
        </ol>
        <h2 {...{
          "id": "interaction-states"
        }}>{`Interaction States`}</h2>
        <p>{`Text Areas support inactive, active, and disabled interaction states.`}</p>
        <p>{`Inactive states are the default state if the text area is not active, disabled, or displaying an
alert.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/8bdfcbf5af6f83328e5b2918168e0a72/536c7/component-states-inactive-textarea-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "29.72972972972973%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of an Inactive Text Area.",
                "title": "Image of an Inactive Text Area.",
                "src": "/static/8bdfcbf5af6f83328e5b2918168e0a72/50383/component-states-inactive-textarea-mobile.png",
                "srcSet": ["/static/8bdfcbf5af6f83328e5b2918168e0a72/1efb2/component-states-inactive-textarea-mobile.png 370w", "/static/8bdfcbf5af6f83328e5b2918168e0a72/50383/component-states-inactive-textarea-mobile.png 740w", "/static/8bdfcbf5af6f83328e5b2918168e0a72/536c7/component-states-inactive-textarea-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Active states are displayed with a blue border, a cursor, and a keyboard.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/81d72639273882ebb463268fa51e3ddb/536c7/component-states-active-textarea-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "29.72972972972973%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of an Active Text Area.",
                "title": "Image of an Active Text Area.",
                "src": "/static/81d72639273882ebb463268fa51e3ddb/50383/component-states-active-textarea-mobile.png",
                "srcSet": ["/static/81d72639273882ebb463268fa51e3ddb/1efb2/component-states-active-textarea-mobile.png 370w", "/static/81d72639273882ebb463268fa51e3ddb/50383/component-states-active-textarea-mobile.png 740w", "/static/81d72639273882ebb463268fa51e3ddb/536c7/component-states-active-textarea-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Disabled states are grayed out to prevent users from interacting with the text area.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f81a527392db23581833481a1b47e7d6/536c7/component-states-disabled-textarea-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "29.72972972972973%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of an Disabled Text Area.",
                "title": "Image of an Disabled Text Area.",
                "src": "/static/f81a527392db23581833481a1b47e7d6/50383/component-states-disabled-textarea-mobile.png",
                "srcSet": ["/static/f81a527392db23581833481a1b47e7d6/1efb2/component-states-disabled-textarea-mobile.png 370w", "/static/f81a527392db23581833481a1b47e7d6/50383/component-states-disabled-textarea-mobile.png 740w", "/static/f81a527392db23581833481a1b47e7d6/536c7/component-states-disabled-textarea-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`When pressing on the text area, the container fill changes to gray to provide touch feedback to the
user.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/59d8cccc4d9e4afc16019f7786119601/536c7/component-states-pressed-textarea-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "29.72972972972973%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Pressed Text Area.",
                "title": "Image of a Pressed Text Area.",
                "src": "/static/59d8cccc4d9e4afc16019f7786119601/50383/component-states-pressed-textarea-mobile.png",
                "srcSet": ["/static/59d8cccc4d9e4afc16019f7786119601/1efb2/component-states-pressed-textarea-mobile.png 370w", "/static/59d8cccc4d9e4afc16019f7786119601/50383/component-states-pressed-textarea-mobile.png 740w", "/static/59d8cccc4d9e4afc16019f7786119601/536c7/component-states-pressed-textarea-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "notification-states"
        }}>{`Notification States`}</h2>
        <p>{`Notification states on mobile include additional visual affordances. They include an icon and a
notification label.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/54564c5a31f2dace46ca083a087b2fa5/536c7/component-states-notification-textarea-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "58.37837837837838%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of an Errors and Alerts on Text Areas.",
                "title": "Image of an Errors and Alerts on Text Areas.",
                "src": "/static/54564c5a31f2dace46ca083a087b2fa5/50383/component-states-notification-textarea-mobile.png",
                "srcSet": ["/static/54564c5a31f2dace46ca083a087b2fa5/1efb2/component-states-notification-textarea-mobile.png 370w", "/static/54564c5a31f2dace46ca083a087b2fa5/50383/component-states-notification-textarea-mobile.png 740w", "/static/54564c5a31f2dace46ca083a087b2fa5/536c7/component-states-notification-textarea-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "variations"
        }}>{`Variations`}</h2>
        <h3 {...{
          "id": "dynamic-height"
        }}>{`Dynamic Height`}</h3>
        <p>{`This is the default behavior. Dynamic height text areas have a fixed base height that grows as the
height of the content exceeds the initial height.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/29e4413d4878390edb4db91fd0c6294a/536c7/component-variation-dynamic-textarea-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "48.64864864864865%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Text Area that grows with the content.",
                "title": "Image of a Text Area that grows with the content.",
                "src": "/static/29e4413d4878390edb4db91fd0c6294a/50383/component-variation-dynamic-textarea-mobile.png",
                "srcSet": ["/static/29e4413d4878390edb4db91fd0c6294a/1efb2/component-variation-dynamic-textarea-mobile.png 370w", "/static/29e4413d4878390edb4db91fd0c6294a/50383/component-variation-dynamic-textarea-mobile.png 740w", "/static/29e4413d4878390edb4db91fd0c6294a/536c7/component-variation-dynamic-textarea-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "fixed-height"
        }}>{`Fixed Height`}</h3>
        <p>{`Fixed height text areas should be used for data dense pages. Their fixed size helps reduce the
overall density and visual load on the page.`}</p>
        <p>{`If content exceeds the height of the Text Area, users can scroll within the input field to view the
rest of the text. When inactive, content is shown to overflow at the bottom. When active, the
content is scrollable, indicated by a scrollbar that appears within the text area. When typing, the
content pushes up, leaving the cursor at the bottom of the input.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/d909fefa821b2162b0b5584db912946e/536c7/component-variation-fixed-textarea-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "29.72972972972973%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Text Area with a fixed height.",
                "title": "Image of a Text Area with a fixed height.",
                "src": "/static/d909fefa821b2162b0b5584db912946e/50383/component-variation-fixed-textarea-mobile.png",
                "srcSet": ["/static/d909fefa821b2162b0b5584db912946e/1efb2/component-variation-fixed-textarea-mobile.png 370w", "/static/d909fefa821b2162b0b5584db912946e/50383/component-variation-fixed-textarea-mobile.png 740w", "/static/d909fefa821b2162b0b5584db912946e/536c7/component-variation-fixed-textarea-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "usage-guidance-1"
        }}>{`Usage Guidance`}</h2>
        <ul>
          <li parentName="ul">{`To ensure we don’t overwhelm users, a max of two Text Areas per page is recommended.`}</li>
          <li parentName="ul">{`On iOS and Android devices, tapping into a field that is not disabled will cause a cursor to
appear as well as a keyboard. As the user types within the Text Area, placeholder text is replaced
with the user’s input. Tapping outside of the Text Area should trigger the inactive state.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-1"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`Use when you need to enter an amount of text that’s longer than a single line.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-1"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`Use a `}<a parentName="li" {...{
              "href": "/components/inputs/text-input#tab=ios"
            }}>{`Text Input`}</a>{` for a single line of text.`}</li>
          <li parentName="ul">{`Use a Time Picker if the user is inputting a time.`}</li>
          <li parentName="ul">{`Use a Date Picker if the user is inputting a date.`}</li>
          <li parentName="ul">{`Use a Rich Text Editor if input styling is needed.`}</li>
          <li parentName="ul">{`Use a Select dropdown, `}<a parentName="li" {...{
              "href": "/components/inputs/radio#tab=ios"
            }}>{`Radio Button`}</a>{`, or
`}<a parentName="li" {...{
              "href": "/components/inputs/checkbox#tab=ios"
            }}>{`Checkbox`}</a>{` if inputted data is predetermined.`}</li>
        </ul>
        <h3 {...{
          "id": "mobile-guidance"
        }}>{`Mobile Guidance`}</h3>
        <p>{`Mobile Text Areas have larger typography for increased readability on smaller screens.`}</p>
        <h2 {...{
          "id": "api-guidelines"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "component-definition"
        }}>{`Component Definition`}</h3>
        <p><inlineCode parentName="p">{`LabelledTextArea`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public struct LabelledTextArea: View
`}</code></pre>
        <p>{`CanvasKit design TextArea`}</p>
        <h3 {...{
          "id": "properties"
        }}>{`Properties`}</h3>
        <p><inlineCode parentName="p">{`body`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public var body: some View
`}</code></pre>
        <h3 {...{
          "id": "methods"
        }}>{`Methods`}</h3>
        <p><inlineCode parentName="p">{`init(text:placeholderText:label:accessibilityLabel:helperText:context:isExpandable:)`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public init(
    text: Binding<String>,
    placeholderText: String?,
    label: String,
    accessibilityLabel: String,
    helperText: String?,
    context: Binding<SemanticContext>,
    isExpandable: Bool = true
)
`}</code></pre>
        <p>{`Create an instance of LabelledTextArea.`}</p>
        <h3 {...{
          "id": "parameters"
        }}>{`Parameters`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`text`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Text binding in field, already localized`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`placeholderText`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Label for possible content of field, already localized`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`label`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Label to be positioned above field, already localized`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`accessibilityLabel`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Accessibility label of field, already localized`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`helperText`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Helper text below field, already localized`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`context`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`SemanticContext`}</inlineCode>{` of the field`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`isExpandable`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Whether height of the field is dynamic`}</td>
            </tr>
          </tbody>
        </table>
        <p><inlineCode parentName="p">{`init(text:placeholderText:label:helperText:context:isExpandable:localizer:)`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public init(
    text: Binding<String>,
    placeholderText: String? = "",
    label: String,
    helperText: String?,
    context: Binding<SemanticContext>,
    isExpandable: Bool = true,
    localizer: LocalizationAdapting
)
`}</code></pre>
        <h2 {...{
          "id": "accessibility-guidelines-1"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Refreshed mobile guidelines will be coming soon!`}</p>
        <h2 {...{
          "id": "content-guidelines-1"
        }}>{`Content Guidelines`}</h2>
        <p>{`Refer to the guidelines on `}<a parentName="p" {...{
            "href": "/guidelines/content/ui-text/placeholder-text"
          }}>{`Placeholder Text`}</a>{` in the Content Style
Guide for more tips on how to write placeholder text.`}</p>
      </TabPanel>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="Android" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?node-id=20%3A4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-2"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/e0665ed7bc2fbeac6ae25d9cb3693521/536c7/component-anatomy-textarea-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "29.72972972972973%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image detailing Text Area anatomy",
                "title": "Image detailing Text Area anatomy",
                "src": "/static/e0665ed7bc2fbeac6ae25d9cb3693521/50383/component-anatomy-textarea-mobile.png",
                "srcSet": ["/static/e0665ed7bc2fbeac6ae25d9cb3693521/1efb2/component-anatomy-textarea-mobile.png 370w", "/static/e0665ed7bc2fbeac6ae25d9cb3693521/50383/component-anatomy-textarea-mobile.png 740w", "/static/e0665ed7bc2fbeac6ae25d9cb3693521/536c7/component-anatomy-textarea-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Label`}</strong>{`: Title of the Text Area.`}</li>
          <li parentName="ol"><strong parentName="li">{`Input Container`}</strong>{`: Rectangular container that houses the placeholder and body text.`}</li>
          <li parentName="ol"><strong parentName="li">{`Placeholder/Body Text`}</strong>{`: Placeholder text is optional and shows an example text entry or
instructional text.`}</li>
        </ol>
        <h2 {...{
          "id": "interaction-states-1"
        }}>{`Interaction States`}</h2>
        <p>{`Text Areas support inactive, active, and disabled interaction states.`}</p>
        <p>{`Inactive states are the default state if the text area is not active, disabled, or displaying an
alert.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/8bdfcbf5af6f83328e5b2918168e0a72/536c7/component-states-inactive-textarea-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "29.72972972972973%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of an Inactive Text Area.",
                "title": "Image of an Inactive Text Area.",
                "src": "/static/8bdfcbf5af6f83328e5b2918168e0a72/50383/component-states-inactive-textarea-mobile.png",
                "srcSet": ["/static/8bdfcbf5af6f83328e5b2918168e0a72/1efb2/component-states-inactive-textarea-mobile.png 370w", "/static/8bdfcbf5af6f83328e5b2918168e0a72/50383/component-states-inactive-textarea-mobile.png 740w", "/static/8bdfcbf5af6f83328e5b2918168e0a72/536c7/component-states-inactive-textarea-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Active states are displayed with a blue border, a cursor, and a keyboard.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/81d72639273882ebb463268fa51e3ddb/536c7/component-states-active-textarea-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "29.72972972972973%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of an Active Text Area.",
                "title": "Image of an Active Text Area.",
                "src": "/static/81d72639273882ebb463268fa51e3ddb/50383/component-states-active-textarea-mobile.png",
                "srcSet": ["/static/81d72639273882ebb463268fa51e3ddb/1efb2/component-states-active-textarea-mobile.png 370w", "/static/81d72639273882ebb463268fa51e3ddb/50383/component-states-active-textarea-mobile.png 740w", "/static/81d72639273882ebb463268fa51e3ddb/536c7/component-states-active-textarea-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Disabled states are grayed out to prevent users from interacting with the text area.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f81a527392db23581833481a1b47e7d6/536c7/component-states-disabled-textarea-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "29.72972972972973%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of an Disabled Text Area.",
                "title": "Image of an Disabled Text Area.",
                "src": "/static/f81a527392db23581833481a1b47e7d6/50383/component-states-disabled-textarea-mobile.png",
                "srcSet": ["/static/f81a527392db23581833481a1b47e7d6/1efb2/component-states-disabled-textarea-mobile.png 370w", "/static/f81a527392db23581833481a1b47e7d6/50383/component-states-disabled-textarea-mobile.png 740w", "/static/f81a527392db23581833481a1b47e7d6/536c7/component-states-disabled-textarea-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`When pressing on the text area, the container fill changes to gray to provide touch feedback to the
user.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/59d8cccc4d9e4afc16019f7786119601/536c7/component-states-pressed-textarea-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "29.72972972972973%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Pressed Text Area.",
                "title": "Image of a Pressed Text Area.",
                "src": "/static/59d8cccc4d9e4afc16019f7786119601/50383/component-states-pressed-textarea-mobile.png",
                "srcSet": ["/static/59d8cccc4d9e4afc16019f7786119601/1efb2/component-states-pressed-textarea-mobile.png 370w", "/static/59d8cccc4d9e4afc16019f7786119601/50383/component-states-pressed-textarea-mobile.png 740w", "/static/59d8cccc4d9e4afc16019f7786119601/536c7/component-states-pressed-textarea-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "notification-states-1"
        }}>{`Notification States`}</h2>
        <p>{`Notification states on mobile include additional visual affordances. They include an icon and a
notification label.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/54564c5a31f2dace46ca083a087b2fa5/536c7/component-states-notification-textarea-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "58.37837837837838%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of an Errors and Alerts on Text Areas.",
                "title": "Image of an Errors and Alerts on Text Areas.",
                "src": "/static/54564c5a31f2dace46ca083a087b2fa5/50383/component-states-notification-textarea-mobile.png",
                "srcSet": ["/static/54564c5a31f2dace46ca083a087b2fa5/1efb2/component-states-notification-textarea-mobile.png 370w", "/static/54564c5a31f2dace46ca083a087b2fa5/50383/component-states-notification-textarea-mobile.png 740w", "/static/54564c5a31f2dace46ca083a087b2fa5/536c7/component-states-notification-textarea-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "variations-1"
        }}>{`Variations`}</h2>
        <h3 {...{
          "id": "dynamic-height-1"
        }}>{`Dynamic Height`}</h3>
        <p>{`This is the default behavior. Dynamic height text areas have a fixed base height that grows as the
height of the content exceeds the initial height.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/29e4413d4878390edb4db91fd0c6294a/536c7/component-variation-dynamic-textarea-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "48.64864864864865%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Text Area that grows with the content.",
                "title": "Image of a Text Area that grows with the content.",
                "src": "/static/29e4413d4878390edb4db91fd0c6294a/50383/component-variation-dynamic-textarea-mobile.png",
                "srcSet": ["/static/29e4413d4878390edb4db91fd0c6294a/1efb2/component-variation-dynamic-textarea-mobile.png 370w", "/static/29e4413d4878390edb4db91fd0c6294a/50383/component-variation-dynamic-textarea-mobile.png 740w", "/static/29e4413d4878390edb4db91fd0c6294a/536c7/component-variation-dynamic-textarea-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "fixed-height-1"
        }}>{`Fixed Height`}</h3>
        <p>{`Fixed height text areas should be used for data dense pages. Their fixed size helps reduce the
density and visual load on the page.`}</p>
        <p>{`If content exceeds the height of the text area, users can scroll within the input field to view the
rest of the text. When inactive, content overflows at the bottom of the input. When active, the
content is scrollable, indicated by a scrollbar that appears within the text area. When typing,
content pushes up, leaving the cursor at the bottom of the input.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/d909fefa821b2162b0b5584db912946e/536c7/component-variation-fixed-textarea-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "29.72972972972973%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Text Area with a fixed height.",
                "title": "Image of a Text Area with a fixed height.",
                "src": "/static/d909fefa821b2162b0b5584db912946e/50383/component-variation-fixed-textarea-mobile.png",
                "srcSet": ["/static/d909fefa821b2162b0b5584db912946e/1efb2/component-variation-fixed-textarea-mobile.png 370w", "/static/d909fefa821b2162b0b5584db912946e/50383/component-variation-fixed-textarea-mobile.png 740w", "/static/d909fefa821b2162b0b5584db912946e/536c7/component-variation-fixed-textarea-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "usage-guidance-2"
        }}>{`Usage Guidance`}</h2>
        <ul>
          <li parentName="ul">{`To ensure we don’t overwhelm users, a max of two Text Areas per page is recommended.`}</li>
          <li parentName="ul">{`On iOS and Android devices, tapping into a field or label that is not disabled will cause a text
cursor to appear as well as a corresponding keyboard. As the user types within the Text Area, any
placeholder text is replaced with the user’s input. Tapping outside of the Text Area should
trigger the inactive state.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-2"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`Use when you need to enter an amount of text that’s longer than a single line.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-2"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`Use a `}<a parentName="li" {...{
              "href": "/components/inputs/text-input#tab=android"
            }}>{`Text Input`}</a>{` for a single line of text.`}</li>
          <li parentName="ul">{`Use a Time Picker if the user is inputting a time.`}</li>
          <li parentName="ul">{`Use a Date Picker if the user is inputting a date.`}</li>
          <li parentName="ul">{`Use a Rich Text Editor if input styling is needed.`}</li>
          <li parentName="ul">{`Use a Select dropdown, `}<a parentName="li" {...{
              "href": "/components/inputs/radio#tab=android"
            }}>{`Radio`}</a>{`, or
`}<a parentName="li" {...{
              "href": "/components/inputs/checkbox#tab=android"
            }}>{`Checkbox`}</a>{` if inputted data is predetermined.`}</li>
        </ul>
        <h3 {...{
          "id": "mobile-guidance-1"
        }}>{`Mobile Guidance`}</h3>
        <p>{`Mobile Text Areas have larger typography for increased readability on smaller screens.`}</p>
        <h2 {...{
          "id": "api-guidelines-1"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "component-definition-1"
        }}>{`Component Definition`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`@Composable
fun NonInteractivePillUiComponent(
    modifier: Modifier = Modifier,
    label: String? = null,
    value: String,
    onValueChange: String -&gt; Unit,
    readOnly: Boolean = false,
    textStyle: TextStyle = LocalTextStyle.current,
    placeholder: String? = null,
    placeholderTextStyle: TextStyle = WorkdayTheme.canvasTypography.bodyMedium,
    heightConfig: TextInputHeightConfig = TextInputHeightConfig.SingleLineConfig,
    interactionSource: MutableInteractionSource = remember { MutableInteractionSource() },
    leadingIcon: @Composable() -&gt; Unit? = null,
    trailingIcon: @Composable() -&gt; Unit? = null,
    visualTransformation: VisualTransformation = VisualTransformation.None,
    helperText: String? = null,
    onClickInputText: () -&gt; Unit = {},
    onClickClearTextIcon: () -&gt; Unit = {},
    keyboardOptions: KeyboardOptions = defaultKeyboardOptions(
        isSingleLine = heightConfig.isSingleLine
    ),
    keyboardActions: KeyboardActions = defaultKeyboardActions(),
    semanticState: SemanticState = SemanticState())
) {
`}</code></pre>
        <h4 {...{
          "id": "parameters-1"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`modifier`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Modifier to be applied to the TextInputUiComponent`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`label`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The text above the input that typically designates the name of the component.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`value`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The text to be displayed inside of the text input.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onValueChange`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Callback lambda that is executed whenever the text of the input changes.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`readOnly`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Removes the ability for user input into the text input. The entire Input surface provides a ripple click animation when readOnly is set to true.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`textStyle`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The style to be applied to the input text. The default textStyle uses the LocalTextStyle defined by the theme.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`placeholder`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`This text is shown inside of the input when the value is empty to provide a hint to the user.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`placeholderTextStyle`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Configures the placeholder typography style.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`heightConfig`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Toggles between a TextInput and TextArea UI Component. Provides min height and max lines configurations for multi-line inputs.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`interactionSource`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The MutableInteractionSource representing the stream of Interactions for this TextInputUiComponent. You can create and pass in your own remembered MutableInteractionSource if you want to observe Interactions and customize the appearance / behavior of this TextInputUiComponent in different Interactions.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`leadingIcon`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The optional leading icon rendered at the start of the input area.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`trailingIcon`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The optional trailing icon rendered at the end of the input area.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`visualTransformation`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Transforms the visual representation of the input value For example, you can use PasswordVisualTransformation to create a password text field. By default no visual transformation is applied.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`helperText`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Helper text is displayed underneath the input area that to give additional context to the user. Error and Alert helper text prefixes are localized and automatically displayed when the component’s semanticState is changed`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onClickInputText`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Callback lambda that is executed when the user clicks on the Input area.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onClickClearTextIcon`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Callback lambda that is executed when the user clicks on the “clear text” trailingIcon that is displayed while the component is focused.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`keyboardOptions`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Software keyboard options that contains configuration such as KeyboardType and ImeAction. For TextInputHeightConfig.SingleLineConfig, by default ImeAction.Done is provided as keyboard option. For TextInputHeightConfig.DynamicMultiLineConfig and TextInputHeightConfig.FixedMultiLineConfig) by default KeyboardOptions.Default is provided.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`keyboardActions`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`when the input service emits an IME action, the corresponding callback is called. Note that this IME action may be different from what you specified in KeyboardOptions.imeAction. By default for KeyboardActions.onDone keyboard is hidden and focus is cleared of the input area.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`semanticState`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Adjusts the state of the Component. This allows for enabling, disabling, warning, error, and required states.`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-2"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Refreshed mobile guidelines will be coming soon!`}</p>
        <h2 {...{
          "id": "content-guidelines-2"
        }}>{`Content Guidelines`}</h2>
        <p>{`Refer to the guidelines on `}<a parentName="p" {...{
            "href": "/guidelines/content/ui-text/placeholder-text"
          }}>{`Placeholder Text`}</a>{` in the Content
Style Guide for more tips on how to write placeholder text.`}</p>
      </TabPanel>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      